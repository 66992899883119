@import './variables.scss';

@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto+Slab:wght@300;500&display=swap');

html, body, div, h1 {
    margin: 0;
    padding: 0;
}

html {
    font-size: 14pt;
    font-family: Lato;
    color: var(--color3);
    line-height: 1.5em;
}

body {
    background: var(--color2);
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

h1 {

}

h2 {
    font-family: Roboto Slab;
    color: var(--color1);
    font-size: 20pt;
    font-weight: normal;
    text-align: center;
}

p {

}

.button-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .button {
        width: 100%;
        max-width: 80%;
        margin: .5em 0;
    }

    @media (min-width: 800px) {
        .button {
            max-width: 30%;
        }
    }
}

.button {
    display: inline-block;
    background: var(--color1);
    color: white;
    padding: .3em 1em;
    border-radius: 10em;

    text-decoration: none;

    font-size: 20pt;
    font-family: Lato;

    text-align: center;
    box-sizing: border-box;

    border: none;
    cursor: pointer;

    &:hover {
        background: var(--color3);
    }

    &:focus {
        outline: 3px black solid;
    }

    @media (max-width: 1200px) {
        font-size: 16pt;
    }
}
